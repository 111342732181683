import React from "react"
import { Link, graphql } from "gatsby"
import rehypeReact from "rehype-react"
import styled from "styled-components"
import { space } from "styled-system"
import { Container } from "react-awesome-styled-grid"

import {
  Layout,
  SEO,
  ProjectHeader,
  H1,
  H2,
  H3,
  H4,
  H5space,
  H6space,
  P,
  Small,
  ArticleSpacer,
  OtherProjects,
} from "../components"
import get from "lodash/get"

const ContentContainer = styled(Container)`
  .gatsby-resp-image-wrapper,
  img {
    max-width: 100%;
  }
  & iframe {
    background-color: white;
    width: 100%;
    height: 800px;
    @media only screen and (max-width: 1920px) {
      height: 665px;
    }
    @media only screen and (max-width: 1440px) {
      height: 50vw;
    }
    @media only screen and (max-width: 1230px) {
      height: 49vw;
    }
    @media only screen and (max-width: 1030px) {
      height: 52vw;
    }
    @media only screen and (max-width: 770px) {
      height: 54vw;
    }
    @media only screen and (max-width: 540px) {
      height: 52vw;
    }
  }
`

const BackToWork = styled.p`
  font-family: "Engravers Gothic";
  font-size: 15px;
  letter-spacing: 1.8px;
  text-align: center;
  line-height: 2;
  & > a {
    transition: opacity 0.2s ease-in-out;
    color: #232222;
    text-decoration: none;
    &:hover {
      opacity: 0.6;
    }
  }
  ${space};
`

const ProjectTemplate = ({ data, location }) => {
  const project = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const otherProjects = data.otherProjects

  const nextProjects = otherProjects.nodes.filter(
    otherProject =>
      Date.parse(otherProject.frontmatter.date) <
      Date.parse(project.frontmatter.date)
  )

  const {
    title,
    description,
    date,
    cover,
    coverMobile,
    card,
    extra,
    client,
  } = project.frontmatter

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      h1: H1,
      h2: H2,
      h3: H3,
      h4: H4,
      h5: H5space,
      h6: H6space,
      em: Small,
      p: P,
      hr: ArticleSpacer,
    },
  }).Compiler

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={title}
        description={description || project.excerpt}
        image={get(card, "publicURL", null)}
      />
      <article
        className="project"
        itemScope
        itemType="http://schema.org/Article"
      >
        <ProjectHeader
          cover={cover}
          coverMobile={coverMobile}
          title={title}
          description={description}
          extra={extra}
          client={client}
          date={date}
        />
        <ContentContainer>{renderAst(project.htmlAst)}</ContentContainer>
      </article>
      <BackToWork mt={4} mb={2}>
        <Link to="/work">
          {"<<"}
          <br />
          back to work
        </Link>
      </BackToWork>
      <OtherProjects projects={nextProjects} />
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        client
        extra
        tags
        card {
          publicURL
        }
        cover {
          publicURL
        }
        coverMobile {
          publicURL
        }
      }
    }
    otherProjects: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { eq: false } }, id: { ne: $id } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          id
          title
          date
          card {
            publicURL
          }
        }
      }
    }
  }
`
